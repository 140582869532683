import { Injectable } from "@angular/core";
import { CentrifugeService } from "./centrifuge.service";
import { Observable } from "rxjs";
import { UserInfoService } from "@@intelease/web/intelease/services";
import { InteleaseMentionHelper, RestClient } from "@@intelease/web/utils";
import { NotificationTypesEnum } from "@@intelease/app-models/notifications";
import { Params } from "@angular/router";
import { DatePipe } from "@angular/common";
import {
  OApiRespCountUnseenModel,
  OApiRespMultiNotificationCategoryDtoModel,
  OApiRespMultiNotificationDtoModel,
} from "@@intelease/api-models/adex-api-model-src";
import { RecordReviewModeEnum } from "@@intelease/app-models/common/src";
import { LocalStorageKey } from "@@intelease/web/common/enums/local-storage.keys";

@Injectable({
  providedIn: "root",
})
export class NotificationsService {
  static readonly USERS_CHANNEL: string = "users";
  static readonly NOTIFICATIONS_BASE_URL = "/notification";

  constructor(
    private centrifugeService: CentrifugeService,
    private restClient: RestClient,
    private datePipe: DatePipe
  ) {}

  connect(options) {
    this.centrifugeService.connect(options);
  }

  getMessagesForCurrentUser() {
    this.centrifugeService.getMessages(
      NotificationsService.USERS_CHANNEL + "#" + UserInfoService.getUserUid()
    );
  }

  getMessagesByChannels(channels: string[]) {
    this.centrifugeService.getMessages(channels.join());
  }

  loadCountOfUnseenNotifications(): Observable<OApiRespCountUnseenModel> {
    return this.restClient.sendGetRequest<OApiRespCountUnseenModel>(
      "/v1",
      `${NotificationsService.NOTIFICATIONS_BASE_URL}/unseen/count`,
      OApiRespCountUnseenModel
    );
  }

  loadNotificationsCategory(): Observable<OApiRespMultiNotificationCategoryDtoModel> {
    return this.restClient.sendGetRequest<OApiRespMultiNotificationCategoryDtoModel>(
      "/v1",
      `${NotificationsService.NOTIFICATIONS_BASE_URL}/category`,
      OApiRespMultiNotificationCategoryDtoModel
    );
  }

  loadNotificationsByCategory(
    category: string
  ): Observable<OApiRespMultiNotificationDtoModel> {
    return this.restClient.sendGetRequest<OApiRespMultiNotificationDtoModel>(
      "/v1",
      `${NotificationsService.NOTIFICATIONS_BASE_URL}`,
      OApiRespMultiNotificationDtoModel,
      {
        params: {
          page: 0,
          offset: 10000,
          category,
        },
      }
    );
  }

  loadUnseenNotifications(): Observable<unknown> {
    return this.restClient.sendGetRequestNoView(
      "/v1",
      `${NotificationsService.NOTIFICATIONS_BASE_URL}/unseenCurrentUser`
    );
  }

  readNotificationByUid(notificationUid: string): Observable<unknown> {
    return this.restClient.sendPutRequestNoView(
      "/v1",
      `${NotificationsService.NOTIFICATIONS_BASE_URL}/${notificationUid}/read`,
      {}
    );
  }

  getNotificationUITextValueByType(
    type: NotificationTypesEnum,
    value
  ): { title?: string; from?: string; to?: string }[] {
    switch (type) {
      case NotificationTypesEnum.ABSTRACT_STATUS_CHANGED: {
        const { from, to } = value;
        const changeStatus = (status) => {
          switch (status) {
            case "PROCESSING_COMPLETED":
              return "Ready";
            case "UNDER_REVIEW":
              return "Under Review";
            case "COMPLETED":
              return "Completed";
            default:
              return status;
          }
        };
        return [
          {
            from: changeStatus(from),
            to: changeStatus(to),
          },
        ];
      }
      case NotificationTypesEnum.MENTIONED_IN_COMMENT: {
        const { from, to } = value;
        // @TODO: (Mohammad.haji) Support mentions in these texts
        return [
          {
            from: from.text,
            to: to?.text,
          },
        ];
      }
      case NotificationTypesEnum.ADDED_TASK:
      case NotificationTypesEnum.DELETED_TASK:
      case NotificationTypesEnum.EDITED_TASK:
      case NotificationTypesEnum.NEW_TASK_ASSIGNED:
      case NotificationTypesEnum.ASSIGNED_TASK_DELETED:
      case NotificationTypesEnum.ASSIGNED_TASK_EDITED:
      case NotificationTypesEnum.ASSIGNED_TASK_UNASSIGNED: {
        const { diff } = value;
        return diff;
      }
      case NotificationTypesEnum.DELETED_COMMENT:
      case NotificationTypesEnum.EDITED_COMMENT:
      case NotificationTypesEnum.ADDED_COMMENT:
      case NotificationTypesEnum.PROVISION_MENTIONED_IN_COMMENT:
      case NotificationTypesEnum.DOCUMENT_MENTIONED_IN_COMMENT:
      case NotificationTypesEnum.ISSUE_MENTIONED_IN_COMMENT: {
        const { from: fromData, to: toData } = value;
        return [
          {
            from:
              fromData && fromData.elements
                ? InteleaseMentionHelper.serializeMention({
                    elements: fromData.elements || [],
                    mentions: fromData.mentions || [],
                  })
                : "",
            to:
              toData && toData.elements
                ? InteleaseMentionHelper.serializeMention({
                    elements: toData.elements || [],
                    mentions: toData.mentions || [],
                  })
                : "",
          },
        ];
      }
      case NotificationTypesEnum.PROJECT_SHARED:
      case NotificationTypesEnum.DASHBOARD_SHARED:
      case NotificationTypesEnum.REPORT_SHARED:
      case NotificationTypesEnum.CALENDAR_SHARED:
      case NotificationTypesEnum.ABSTRACT_SHARED:
      case NotificationTypesEnum.MASS_UPLOAD_ABSTRACTION_FINISHED: {
        const message = value?.metadata?.message || "";
        return [
          {
            from: message,
            to: undefined,
          },
        ];
      }
      case NotificationTypesEnum.REMINDER: {
        const { entityName, metadata, targetDate } = value;
        const { fieldUiName } = metadata;
        const dateValue = new Date().setFullYear(
          targetDate[0],
          targetDate[1],
          targetDate[2]
        );
        return [
          {
            from: `The ${fieldUiName} for the record "<strong>${entityName}</strong>" is on ${this.datePipe.transform(
              dateValue,
              "MMMM dd, yyyy"
            )}`,
          },
        ];
      }
      case NotificationTypesEnum.PROJECT_REQUEST_ACCESS:
      case NotificationTypesEnum.DASHBOARD_REQUEST_ACCESS:
      case NotificationTypesEnum.REPORT_REQUEST_ACCESS:
      case NotificationTypesEnum.CALENDAR_REQUEST_ACCESS:
      case NotificationTypesEnum.ABSTRACT_REQUEST_ACCESS:
      default:
        return [value];
    }
  }

  getRecordReviewUrlPart(recordReviewMode?: RecordReviewModeEnum) {
    switch (
      recordReviewMode ||
      localStorage.getItem(LocalStorageKey.RECORD_REVIEW_MODE)
    ) {
      case RecordReviewModeEnum.V2:
        return "/record-review";
      case RecordReviewModeEnum.V1:
      default:
        return "/abstract-review";
    }
  }

  getNotificationUrl(notification): { type: string; link: string } {
    const {
      abstractUid,
      abstractTitle,
      taskTitle,
      taskUid,
      type,
      docAbstractUid,
      commentUid,
      provisionUid,
      entityUid,
      entityType,
      metadata,
      recordReviewMode,
    } = notification;
    const payload = { link: "", type: "" };
    if (
      type === NotificationTypesEnum.MENTIONED_IN_COMMENT ||
      type === NotificationTypesEnum.DOCUMENT_MENTIONED_IN_COMMENT
    ) {
      payload.type = "ABSTRACT";
      if (commentUid) {
        payload.link = `${this.getRecordReviewUrlPart(
          recordReviewMode
        )}/${abstractUid}/pdf/documents/${docAbstractUid}/comments/${commentUid}`;
      } else {
        payload.link = `${this.getRecordReviewUrlPart(
          recordReviewMode
        )}/${abstractUid}/pdf/documents/${docAbstractUid}`;
      }
    } else if (type === NotificationTypesEnum.PROVISION_MENTIONED_IN_COMMENT) {
      payload.type = "ABSTRACT";
      if (commentUid) {
        payload.link = `${this.getRecordReviewUrlPart(
          recordReviewMode
        )}/${abstractUid}/pdf/provisions/${provisionUid}`;
      } else {
        payload.link = `${this.getRecordReviewUrlPart(
          recordReviewMode
        )}/${abstractUid}/pdf/documents/${docAbstractUid}`;
      }
    } else if (type === NotificationTypesEnum.ISSUE_MENTIONED_IN_COMMENT) {
      const { projectUid, issueUid, kanbanBoardUid, issueListUid } = metadata;
      payload.type = "PROJECT";
      payload.link = `/projects/${projectUid}/kanban/${kanbanBoardUid}/issues/${issueListUid}/issue/${issueUid}`;
    } else if (type === NotificationTypesEnum.REMINDER) {
      const { calendarUid } = metadata;
      payload.type = "CALENDAR";
      payload.link = `/calendars/${calendarUid}/detail`;
    } else if (abstractUid && abstractTitle) {
      payload.type = "ABSTRACT";
      payload.link = `${this.getRecordReviewUrlPart(
        recordReviewMode
      )}/${abstractUid}/pdf`;
    } else if (taskTitle && taskUid) {
      payload.type = "TASK";
      payload.link = "/tasks/list";
    } else if (entityUid && entityType) {
      if (type.endsWith("_REQUEST_ACCESS")) {
        payload.type = "REQUEST_ACCESS";
        payload.link = this.getLinkForEntity(
          entityUid,
          entityType,
          recordReviewMode
        );
      } else if (type.endsWith("_SHARED")) {
        payload.type = "SHARE";
        payload.link = this.getLinkForEntity(
          entityUid,
          entityType,
          recordReviewMode
        );
      }
    } else if (
      type === NotificationTypesEnum.MASS_UPLOAD_ABSTRACTION_FINISHED
    ) {
      payload.type = "DIRECTORY";
      payload.link = this.getLinkForEntity(metadata.folderUid, "DIRECTORY");
    }
    return payload;
  }

  getLinkForEntity(
    entityUid: string,
    entityType: string,
    recordReviewMode?: RecordReviewModeEnum
  ): string {
    // TODO(apoorv): Remove the postfix part of the url.
    switch (entityType) {
      case "PROJECT":
        return `/projects/${entityUid}/kanban`;
      case "CALENDAR":
        return `/calendars/${entityUid}/details`;
      case "REPORT":
        return `/reports/${entityUid}/edit`;
      case "DASHBOARD":
        return `/dashboards/${entityUid}`;
      case "FINAL_ABSTRACT":
        return `${this.getRecordReviewUrlPart(recordReviewMode)}/${entityUid}`;
      case "DIRECTORY":
        return `/drive/folders/${entityUid}`;
    }
  }

  getNotificationQueryParams(notification): Params {
    const { type } = notification;
    if (type === NotificationTypesEnum.PROVISION_MENTIONED_IN_COMMENT) {
      return {
        selectedTab: "COMMENTS",
      };
    }
    if (type.endsWith("_REQUEST_ACCESS")) {
      if (notification.creator?.uid) {
        return {
          shareWith: notification.creator.uid,
        };
      }
    }
    if (type === NotificationTypesEnum.REMINDER) {
      const { entityUid, mentionUid } = notification;
      return {
        abstractUid: entityUid,
        mentionUid: mentionUid,
      };
    }
    return {};
  }

  notificationServerStates(): Observable<unknown> {
    return this.centrifugeService.getStates();
  }

  disconnect(): void {
    this.centrifugeService.disconnect();
  }
}
